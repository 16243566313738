<template>
  <div>
    <div class="super">
      <div class="holder">
        <SmallNav />
        <div class="container">
          <div class="property-holder">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr class="table-row table-head">
                    <th scope="col">PIN</th>
                    <th scope="col">IMAGE</th>
                    <th scope="col">TITLE</th>
                    <th scope="col">LOCATION</th>
                    <th scope="col">BED</th>
                    <th scope="col">BATH</th>
                    <th scope="col">TOILET</th>
                    <th scope="col">RATE</th>
                    <th scope="col">STATUS</th>
                  </tr>
                </thead>
                <tbody v-for="(property, index) in properties" :key="index">
                  <tr class="table-row">
                    <td>{{ property.pin }}</td>
                    <td>
                      <img
                        class="prop-img-list"
                        :src="property.images[0]"
                        alt="income"
                      />
                    </td>
                    <td>{{ property.title }}</td>
                    <td>{{ property.address }}</td>
                    <td>{{ property.bedrooms }}</td>
                    <td>{{ property.bathrooms }}</td>
                    <td>{{ property.toilets }}</td>
                    <td>&#8358;{{ property.price }}</td>
                    <td><div class="green-dot"></div></td>
                  </tr>
                  <!-- <tr class="table-row">
                       <td>1y3gn46</td>
                        <td><img class="prop-img-list" src="../assets/images/prop2.svg" alt="income" /></td>
                        <td>Repair of toilet drainage</td>
                        <td>Guzape</td>
                        <td>4</td>
                        <td>2</td>
                        <td>2</td>
                        <td>#10,000</td>
                        <td><div class="yellow-dot"></div></td>
                       
                      </tr>
                      <tr class="table-row">
                       <td>1y3e47</td>
                        <td><img class="prop-img-list" src="../assets/images/prop3.svg" alt="income" /></td>
                        <td>Repair of leaking roof</td>
                        <td>Kubwa Arab road</td>
                        <td>4</td>
                        <td>2</td>
                        <td>2</td>
                        <td>#30,000</td>
                        <td><div class="red-dot"></div></td>
                       
                      </tr> -->
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <router-link to="/addproperty">
              <button class="btn newPropBtn">Add New Property</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
export default {
  name: "Properties",

  components: {
    SmallNav
  },
  data() {
    return {
      firstname: "",
      lastname: "",
      id: "",
      properties: []
      // propImg: []
    };
  },
  created() {
    this.$root.$refs.Properties = this;
  },
  mounted() {
    this.firstname = JSON.parse(localStorage.getItem("shelta-user")).firstname;
    this.lastname = JSON.parse(localStorage.getItem("shelta-user")).lastname;
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.getProperties();
    // this.$root.$emit(getProperties());
  },
  methods: {
    getProperties: async function() {
      try {
        await Api.getRequest(`fetchpropertiesbylandlordid/${this.id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "Loading properies...");
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.properties = res.data.properties;
              this.$store.commit("setApiSuccess", "Propeties loades");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } finally {
        console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$black: #000000;
$fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
$fontFamily2: url("https://fonts.googleapis.com/css2?family=Gotham-Ultra:wght@100&display=swap");
$fontFamily3: url("https://fonts.googleapis.com/css2?family=Roboto-Regular:wght@100&display=swap");

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
}
.property-holder {
  background: $secondary;
  margin-top: 2rem;
}

.table-row {
  text-align: start;
}
.table-head > th:last-child {
  text-align: center;
}
.table-head > th {
  border-top: none;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  align-items: center;
  color: black;
}
.table-row > td {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: $black;
}

.green-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #2fc643;
  margin: auto;
}
.yellow-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ffd704;
  margin: auto;
}
.red-dot {
  width: 1rem;
  height: 1rem;
  border-radius: 100px;
  background: #ea0000;
  margin: auto;
}
.table-base > td {
  background: linear-gradient(180deg, #0033ea 0%, #000000 131.31%), #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
}

.property-text-wrap {
  display: flex;
  justify-content: space-between;
}
.propertt-list-holder {
  background: $secondary;
  border-radius: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.property-text-wrap p {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #000000;
}

.prop-img-list {
  width: 4rem;
  height: 2rem;
  border-radius: 5px;
}

.newPropBtn {
  background: $primary;
  color: $secondary;
  font-family: $fontFamily3;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  box-shadow: none;
  // letter-spacing: -0.05em;
  color: #ffffff;
}

@media only screen and (max-width: 599px) {
  .table-row > td {
    white-space: pre !important;
  }
}
</style>
